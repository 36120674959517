<template>
  <div class="home">
    <v-container>
      <h2>Informacion General</h2>
    </v-container>
    <v-divider></v-divider>
    <v-container class="mt-10">
      <v-row align="center">
        <v-col cols="2">
          Incidencias al dia
        </v-col>
        <v-col cols="3" align="left">
        <v-menu
                ref="menu"
                v-model="showMenu"
                :close-on-content-click="false"
                :return-value.sync="fechahoy"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechahoy"
                    class="mr-2"
                    v-bind="attrs"
                    :placeholder="placeholder"
                    required
                    :append-icon="'mdi-calendar-check'"
                    :rules="rules"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="fechahoy"
                  no-title
                  scrollable
                  locale="ES-es"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showMenu = false"
                    >Cancelar</v-btn
                  >
                  <v-btn text color="primary" @click="$refs.menu.save(fechahoy);getDatosGrap(fechahoy)"
                    >Aceptar</v-btn
                  >
                </v-date-picker>
              </v-menu>
        

        </v-col>
      </v-row>  
      <v-row align="center">
        <v-col cols="6">
          <h3>  </h3>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card color="white" :elevation="hover ? 4 : 2">
              <v-container>
                  <AreaChart v-if="loaded" :chartData="dani" :chartLabels="asset" :download="true"/>
                </v-container>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>  
      <v-row v-if="loaded2" >
        <v-col  cols="12" sm="12">
          <v-container mt-5>
            <h3>  {{labelArea}} </h3>
          </v-container>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card  color="white" :elevation="hover ? 16 : 2">
              <v-row>
                <v-container>
                  <ColumsChart :chartData="danicol" :chartData2="danicol2" :chartLabels="assetcol" />
                </v-container>
              </v-row>
            </v-card>
          </v-hover>
          
        </v-col>
      </v-row>  
      
      
    </v-container>
  </div>
</template>

<script>

//import BarChart from '@/components/BarChart'
//import Barsample from '@/components/BarChart.js'
import AreaChart  from '@/components/AreaChart'
import ColumsChart from '@/components/AreaChart2'
//const axios=require("axios")
import axios from "axios";
export default {
  name: 'Home',
  components: {
  //  BarChart,
    //Barsample
    AreaChart,
    ColumsChart
  },
  data: () => ({
    loaded: false,
    loaded2: false,
    captionAre: 'Areas',
    captionColumna: 'Columnas',
    dani: [],
    asset: [],
    danicol: [],
    assetcol: [],
    danicol2: [],
    assetcol2: [],
    labelArea: 'Incidencias x Area',
    id_area_count: 0,
    fechahoy:'',
    placeholder: "",
    rules: [(v) => !!v || "This field is required"],
    showMenu: false
  }),

  methods: {
    getDate() {
       console.log("Fecha : ", this.fechahoy);
    },
    async getDatosGrap(datehoy) {
      this.loaded = false;
      this.loaded2 = false;
    try{
      console.log('date : ', datehoy)
      
      const str_url = "https://psisantamonica.mx/daimler/db/grafica_gralxarea.php?date_reg=" + datehoy;
      console.log('url : ', str_url);
      
      await axios.get(str_url)
      .then( response => {
        console.log('data : ' ,response.data);
        this.dani = response.data['grapareas'].map(item => item.valor)
        this.asset = response.data['grapareas'].map(item => item.name)
        this.danicol = response.data['grapcolumn'].map(item => item.valor)
        this.assetcol = response.data['grapcolumn'].map(item => item.name)
        this.danicol2 = response.data['grapcolumn1'].map(item => item.valor)
        this.id_area_count = response.data['idarea']
        this.labelArea = 'Incidencias x Columna en [ ' + response.data['namearea'] + ' ]'

        if (this.id_area_count == 0 ){
          this.loaded2 = false;
        }else{
          this.loaded2 = true;
        }
        
        console.log('columnas valor : ' , this.danicol);
        console.log('columnas name: ' , this.assetcol);
        
      })
      this.loaded = true
    } catch (e) {
      console.error(e)
    }

    }

  },
  

  async mounted() {
    
      var pakainfo_dt = new Date();
      var month = ('0' + (pakainfo_dt.getMonth() + 1)).slice(-2);
      var date = ('0' + pakainfo_dt.getDate()).slice(-2);
      var year = pakainfo_dt.getFullYear();
      var dateTimeNewFormat = year + '-' + month + '-' + date;
      this.fechahoy = dateTimeNewFormat;
      this.placeholder = dateTimeNewFormat;

      this.getDatosGrap(dateTimeNewFormat);
    

  },


}
</script>
<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>
<style scoped>
.border {
  border-right: 1px solid grey
}
</style>