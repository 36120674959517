var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-container',[_c('h2',[_vm._v("Informacion General")])]),_c('v-divider'),_c('v-container',{staticClass:"mt-10"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Incidencias al dia ")]),_c('v-col',{attrs:{"cols":"3","align":"left"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.fechahoy,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.fechahoy=$event},"update:return-value":function($event){_vm.fechahoy=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"placeholder":_vm.placeholder,"required":"","append-icon":'mdi-calendar-check',"rules":_vm.rules},model:{value:(_vm.fechahoy),callback:function ($$v) {_vm.fechahoy=$$v},expression:"fechahoy"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"ES-es"},model:{value:(_vm.fechahoy),callback:function ($$v) {_vm.fechahoy=$$v},expression:"fechahoy"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showMenu = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.save(_vm.fechahoy);_vm.getDatosGrap(_vm.fechahoy)}}},[_vm._v("Aceptar")])],1)],1)],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h3'),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"color":"white","elevation":hover ? 4 : 2}},[_c('v-container',[(_vm.loaded)?_c('AreaChart',{attrs:{"chartData":_vm.dani,"chartLabels":_vm.asset,"download":true}}):_vm._e()],1)],1)]}}])})],1)],1),(_vm.loaded2)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-container',{attrs:{"mt-5":""}},[_c('h3',[_vm._v(" "+_vm._s(_vm.labelArea)+" ")])]),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"color":"white","elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-container',[_c('ColumsChart',{attrs:{"chartData":_vm.danicol,"chartData2":_vm.danicol2,"chartLabels":_vm.assetcol}})],1)],1)],1)]}}],null,false,3770940916)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }