<template>
    <v-navigation-drawer v-model="drawer" permanent color="#F4F5F9" app>
        <v-list-item class="px-2 py-5">
            <v-list-item-title class="text-capitalize" align="center">
                <v-icon x-large>fab fa-bitcoin</v-icon> PSI Santa Monica
            </v-list-item-title>
        </v-list-item>
        <v-list nav dense>
            <v-list-item-group v-model="selectedItem" color="deep-purple">
                <v-list-item v-for="(item, i) in items" :key="i">
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <router-link :to="item.route"><v-list-item-title v-text="item.text"></v-list-item-title>
                        </router-link>
                        </v-list-item-content>
                    </v-list-item> 
            </v-list-item-group>
        </v-list>
        
    </v-navigation-drawer>
</template>
<script>
export default {
    data: () => ({
        selectedItem: 0,
        drawer: null,
        items: [
            {icon: 'mdi-view-dashboard', text: 'Dashboard', route: '/'},
            {icon: 'fas fa-money-check-alt', text: 'Grafica Incidencias', route: '/repgraf'},
           // {icon: 'fas fa-money-check-alt', text: 'Informe Incidencias', route: '/repincidencias'},
            {icon: 'fas fa-dolly', text: 'Informe Rutinas', route: '/reprutina'},
            {icon: 'fas fa-exchange-alt', text: 'Configuración', route: '/viewmodal'},
            {icon: 'fas fa-cog', text: 'Cerrar Sesion', route: '/tools'},
        ]
        })
}
</script>
<style scoped>
div >>> .v-list a {
    text-decoration: none;
}
</style>