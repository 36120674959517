<script>
  import { Bar } from 'vue-chartjs'


  export default {
    extends: Bar,
    props: {
      chartData: {
        type: Array,
        required: true,
      
      },
      chartLabels: {
        type: Array,
        required: true
      },
    },
    data () {
      return {
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 1
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
                title: {
                    color: 'red',
                    display: true,
                    text: 'Areas'
                    },
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted () {
      this.renderChart({
        labels: this.chartLabels,
        datasets: [
          {
            label: 'Incidencias',
            borderColor: '#000000',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            backgroundColor: '#ff0000',
            data: this.chartData
          }
        ]
      }, this.options)
    },
  
  }
</script>